import { useResetKey } from '@awl/core/src/hooks/useResetKey';
import { Lang } from '@awl/core/src/types/i18n';
import { useEffect } from 'react';

export type ThemeCaptcha = 'dark' | 'light';
type Params = {
  namespace: string;
  onChange: (val: string) => void;
  lang: Lang;
  theme?: ThemeCaptcha;
};

let id = 0;

export const useReCaptcha = ({ namespace, lang, onChange, theme }: Params) => {
  const resetKey = useResetKey(namespace);

  const reset = () => {
    onChange('');
    resetKey.reset();
  };

  const onVerify = (reCaptchaResponse: string) => {
    onChange(reCaptchaResponse);
  };

  useEffect(reset, [lang]);

  return {
    lang,
    id: `${resetKey.key}-${id++}`,
    key: resetKey.key,
    reset,
    onVerify,
    theme,
  };
};
