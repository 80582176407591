import { contactUs } from '@awl/core/src/services/landing';
import {
  getQueryErrorMsg,
  getQueryViolations,
} from '@awl/core/src/utils/request';
import { useMutation, useQueryClient } from 'react-query';

export const useContactUsMutation = () => {
  const queryClient = useQueryClient();

  const contactUsMutation = useMutation(contactUs, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY);
    },
  });

  return {
    ...contactUsMutation,
    errorMsg: getQueryErrorMsg(contactUsMutation),
    violations: getQueryViolations(contactUsMutation),
  };
};

const QUERY_KEY = 'contactUs';
