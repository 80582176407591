import { useLandingLang } from '@awl/core/src/hooks/useLandingLang';
import { useToggler } from '@awl/core/src/hooks/useToggler';
import useOutsideClickRef from '@rooks/use-outside-click-ref';
import { LangSwitch } from '@src/components/LangSwitch';
import { goToAuth } from '@src/utils/location';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import { Nav } from '../Nav';
import styles from './Header.module.scss';
import LogoMobileIcon from './img/logo-mobile.svg';
import LogoIcon from './img/logo.svg';
import NavCrossIcon from './img/nav-cross.svg';
import NavListIcon from './img/nav-list.svg';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const lang = useLandingLang();
  const nav = useToggler();
  const clear = () => {
    lang.toggle(false);
    nav.toggle(false);
  };
  const [rootRef] = useOutsideClickRef(clear);

  return (
    <div
      ref={rootRef}
      className={cn(styles.root)}
    >
      <div className={cn(styles.nav, { [styles.isOpen]: nav.isOpen })}>
        <div className={cn(styles.navWrapper)}>
          <Nav onClick={clear} />
          <div
            className={cn(styles.actions, {
              [styles.isOpen]: nav.isOpen,
            })}
          >
            <div className={cn(styles.buttons)}>
              <Button
                variant='outlined'
                className={cn(styles.button, styles.registration)}
                onClick={() => goToAuth('/registration')}
              >
                {t('pages.registration.title')}
              </Button>
              <Button
                variant='link'
                className={cn(styles.button, styles.login)}
                onClick={() => goToAuth('/login')}
              >
                {t('pages.login.title')}
              </Button>
            </div>
            <div className={cn(styles.lang)}>
              <LangSwitch
                isOpenByToggle={lang.isOpen}
                onToggle={lang.toggle}
                dropdownClassName={cn(styles.langDropdown)}
                title='icon'
                onChange={lang.onChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(styles.navOverlay, {
          [styles.isOpen]: nav.isOpen,
        })}
        onClick={clear}
      />
      <div className={cn(styles.logoWrapper)}>
        <Link
          className={cn(styles.logoLink)}
          to={'/'}
        >
          <LogoIcon className={cn(styles.logo)} />
          <LogoMobileIcon className={cn(styles.logoMobile)} />
        </Link>
      </div>

      <div className={cn(styles.menu)}>
        <div
          className={cn(styles.toggle, {
            [styles.isOpen]: nav.isOpen,
          })}
          onClick={() => nav.toggle()}
        >
          {nav.isOpen ? <NavCrossIcon /> : <NavListIcon />}
        </div>
      </div>
    </div>
  );
};
