import { PAGES_CONFIG } from '@awl/core/src/constants/pages';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import LogoIcon from './img/logo.svg';
import Requisites from './img/requisites.png';
import RequisitesMobile from './img/requisitesMobile.png';
interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.root)}>
      <LogoIcon className={cn(styles.logo, styles.cell)} />
      <div className={cn(styles.affiliate, styles.cell)}>
        <img
          className={cn(styles.requisites)}
          src={Requisites}
        />
        <img
          className={cn(styles.requisitesMobile)}
          src={RequisitesMobile}
        />
      </div>
      <div className={cn(styles.copyright, styles.cell)}>
        <div className={cn(styles.copyrightInner)}>
          {/* @TODO: use actual value https://jira.8adm.com/browse/LG-214 */}
          Copyright © 2015-2022 DepHouse
          <br />
          All rights reserved and protected by law
          <br />
          <Link
            className={cn(styles.privacy)}
            to={PAGES_CONFIG.privacy.route}
          >
            {t('pages.privacy.title')}
          </Link>
        </div>
      </div>
    </div>
  );
};
