import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Advantages.module.scss';
import CartSuitsIcon from './img/cardSuits.svg';
import CartSuitsMobileIcon from './img/cardSuitsMobile.svg';
import CoinsIcon from './img/coins.svg';
import GeoIcon from './img/geo.svg';
import GraphIcon from './img/graph.svg';
import PartnersIcon from './img/partners.svg';
import TicketIcon from './img/ticket.svg';
import WalletIcon from './img/wallet.svg';

export const Advantages = () => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.root)}>
      <CartSuitsIcon className={cn(styles.cartSuits)} />
      <CartSuitsMobileIcon className={cn(styles.cartSuitsMobile)} />
      <h2
        className={cn(styles.title)}
        dangerouslySetInnerHTML={{
          __html: t('pages.landing.advantages.title'),
        }}
      />
      <div className={cn(styles.row)}>
        <div className={cn(styles.item)}>
          <TicketIcon className={cn(styles.itemIcon)} />
          <p className={cn(styles.itemText)}>
            {t('pages.landing.advantages.items.item_1')}
          </p>
        </div>
        <div className={cn(styles.item)}>
          <GeoIcon className={cn(styles.itemIcon)} />
          <p className={cn(styles.itemText)}>
            {t('pages.landing.advantages.items.item_2')}
          </p>
        </div>
        <div className={cn(styles.item)}>
          <WalletIcon className={cn(styles.itemIcon)} />
          <p className={cn(styles.itemText)}>
            {t('pages.landing.advantages.items.item_3')}
          </p>
        </div>
      </div>
      <div className={cn(styles.row)}>
        <div className={cn(styles.item, styles.noBottomLine)}>
          <CoinsIcon className={cn(styles.itemIcon)} />
          <p className={cn(styles.itemText)}>
            {t('pages.landing.advantages.items.item_4')}
          </p>
        </div>
        <div className={cn(styles.item, styles.noBottomLine)}>
          <GraphIcon className={cn(styles.itemIcon)} />
          <p className={cn(styles.itemText)}>
            {t('pages.landing.advantages.items.item_5')}
          </p>
        </div>
        <div className={cn(styles.item, styles.noBottomLine)}>
          <PartnersIcon className={cn(styles.itemIcon)} />
          <p className={cn(styles.itemText)}>
            {t('pages.landing.advantages.items.item_6')}
          </p>
        </div>
      </div>
    </div>
  );
};
