import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AboutUs.module.scss';
import Image from './img/image.svg';

export const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.root}>
      <div className={styles.content}>
        <h2
          className={cn(styles.title)}
          dangerouslySetInnerHTML={{
            __html: t('pages.landing.about.title'),
          }}
        />
        <div className={styles.text}>
          <p className={cn(styles.textItem)}>
            {t('pages.landing.about.items.item_1')}
          </p>
          <p className={cn(styles.textItem)}>
            {t('pages.landing.about.items.item_2')}
          </p>
          <p className={cn(styles.textItem)}>
            {t('pages.landing.about.items.item_3')}
          </p>
        </div>
      </div>
      <div className={styles.image}>
        <Image className={styles.imageBg} />
      </div>
    </section>
  );
};
