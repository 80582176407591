import cn from 'classnames';
import React from 'react';
import MasterCardMobileIcon from './img/master-card-mobile.svg';
import MasterCardIcon from './img/master-card.svg';
import PayPalMobileIcon from './img/paypal-mobile.svg';
import PayPalIcon from './img/paypal.svg';
import PayPassMobileIcon from './img/paypass-mobile.svg';
import PayPassIcon from './img/paypass.svg';
import RobokassaMobileIcon from './img/robokassa-mobile.svg';
import RobokassaIcon from './img/robokassa.svg';
import VisaCardMobileIcon from './img/visa-card-mobile.svg';
import VisaCardIcon from './img/visa-card.svg';
import WebmoneyMobileIcon from './img/webmoney-mobile.svg';
import WebmoneyIcon from './img/webmoney.svg';
import WesternUnionMobileIcon from './img/western-union-mobile.svg';
import WesternUnionIcon from './img/western-union.svg';
import styles from './Pays.module.scss';

interface PaysProps {}

export const Pays: React.FC<PaysProps> = () => {
  return (
    <div className={cn(styles.root)}>
      <div className={cn(styles.inner)}>
        <div className={cn(styles.pay)}>
          <VisaCardIcon className={cn(styles.icon)} />
          <VisaCardMobileIcon className={cn(styles.iconMobile)} />
        </div>
        <div className={cn(styles.pay)}>
          <MasterCardIcon className={cn(styles.icon)} />
          <MasterCardMobileIcon className={cn(styles.iconMobile)} />
        </div>
        <div className={cn(styles.pay)}>
          <PayPalIcon className={cn(styles.icon)} />
          <PayPalMobileIcon className={cn(styles.iconMobile)} />
        </div>
        <div className={cn(styles.pay)}>
          <WebmoneyIcon className={cn(styles.icon)} />
          <WebmoneyMobileIcon className={cn(styles.iconMobile)} />
        </div>
        <div className={cn(styles.pay)}>
          <RobokassaIcon className={cn(styles.icon)} />
          <RobokassaMobileIcon className={cn(styles.iconMobile)} />
        </div>
        <div className={cn(styles.pay)}>
          <WesternUnionIcon className={cn(styles.icon)} />
          <WesternUnionMobileIcon className={cn(styles.iconMobile)} />
        </div>
        <div className={cn(styles.pay)}>
          <PayPassIcon className={cn(styles.icon)} />
          <PayPassMobileIcon className={cn(styles.iconMobile)} />
        </div>
      </div>
    </div>
  );
};
