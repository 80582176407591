import { useChevronProvider } from '@awl/core/src/providers/useChevronProvider';
import cn from 'classnames';
import React from 'react';

interface FlagProps {
  isOpen?: boolean;
  className?: string;
  openClassName?: string;
  children: React.ReactNode;
}

export const Chevron: React.FC<FlagProps> = ({
  isOpen,
  className,
  openClassName,
  children,
}) => {
  const { styles: proStyles } = useChevronProvider();

  return (
    <div
      className={cn(
        proStyles.root,
        className,
        { [proStyles.isOpen]: isOpen },
        openClassName && { [openClassName]: isOpen }
      )}
    >
      {children}
    </div>
  );
};
