import { useDropdownProvider } from '@awl/core/src/providers/useDropdownProvider';
import cn from 'classnames';
import React from 'react';
import styles from './Dropdown.module.scss';

interface DropdownProps {
  isOpen?: boolean;
  items: (DropdownItem | undefined)[];
  className?: string;
}

interface DropdownItem {
  id: string;
  isActive?: boolean;
  onClick: () => void;
  title: string | JSX.Element;
}

export const Dropdown: React.FC<DropdownProps> = ({
  className,
  isOpen,
  items,
}) => {
  const { styles: proStyles } = useDropdownProvider();
  return (
    <ul
      className={cn(proStyles.root, styles.root, className, {
        [proStyles.isOpen]: isOpen,
      })}
    >
      {items.map((item) => {
        if (!item) return null;

        const { id, title, isActive, onClick } = item;

        return (
          <li
            key={id}
            className={cn(proStyles.item, styles.item, {
              [proStyles.isActive]: isActive,
            })}
            onClick={() => !isActive && onClick()}
          >
            {title}
          </li>
        );
      })}
    </ul>
  );
};
