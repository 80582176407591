import { useReCaptchaProvider } from '@awl/core/src/providers/useReCaptchaProvider';
import { ReCaptchaProps } from '@awl/core/src/types/reCaptcha';
import React from 'react';

export const ReCaptcha: React.FC<ReCaptchaProps> = (props) => {
  const { grecaptchaId, className, callbackName } = useReCaptchaProvider(props);

  return (
    <div
      id={grecaptchaId}
      className={className}
      data-sitekey={process.env.RECAPTCHA_KEY}
      data-callback={callbackName}
    />
  );
};
