import { useScrollToHash } from '@awl/core/src/hooks/useScrollToHash';
import { Advantages } from '@src/components/Advantages';
import cn from 'classnames';
import React from 'react';
import { Element } from 'react-scroll';
import { AboutUs } from '../AboutUs';
import { Banner } from '../Banner';
import { ContactUs } from '../ContactUs';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { Pays } from '../Pays';
import styles from './Layout.module.scss';

interface LayoutProps {}

export const Layout: React.FC<LayoutProps> = () => {
  useScrollToHash();

  return (
    <div className={cn(styles.root)}>
      <div className={cn(styles.inner)}>
        <div className={cn(styles.blockWrapper, styles.header)}>
          <div className={cn(styles.block)}>
            <div className={cn(styles.blockInner)}>
              <Header />
            </div>
          </div>
        </div>
        <div className={cn(styles.blockWrapper, styles.banner)}>
          <div className={cn(styles.block)}>
            <div className={cn(styles.deco, styles.soundOfYourMoneyBanner)} />
            <div className={cn(styles.blockInner)}>
              <Banner />
            </div>
          </div>
        </div>
        <Element
          name='#about'
          className={cn(styles.blockWrapper, styles.about)}
        >
          <div className={cn(styles.block)}>
            <div className={cn(styles.deco, styles.vectorAboutUs1)} />
            <div className={cn(styles.deco, styles.vectorAboutUs2)} />
            <div className={cn(styles.blockInner)}>
              <AboutUs />
            </div>
          </div>
        </Element>
        <Element
          name='#advantages'
          className={cn(styles.blockWrapper, styles.advantages)}
        >
          <div className={cn(styles.block)}>
            <div className={cn(styles.blockInner)}>
              <Advantages />
            </div>
          </div>
        </Element>
        <Element
          name='#contact'
          className={cn(styles.blockWrapper, styles.contact)}
        >
          <div className={cn(styles.block)}>
            <div className={cn(styles.deco, styles.vectorContactUs1)} />
            <div className={cn(styles.blockInner)}>
              <ContactUs />
            </div>
          </div>
        </Element>
        <div className={cn(styles.blockWrapper, styles.pays)}>
          <div className={cn(styles.block)}>
            <div className={cn(styles.deco, styles.soundOfYourMoneyPays)} />
            <div className={cn(styles.blockInner)}>
              <Pays />
            </div>
          </div>
        </div>
        <div className={cn(styles.blockWrapper, styles.footer)}>
          <div className={cn(styles.block)}>
            <div className={cn(styles.blockInner)}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
