import 'normalize.css';
import React from 'react';
import { withTranslation } from 'react-i18next';
import './assets/styles/global.scss';
import { Layout } from './components/Layout';

function App() {
  return <Layout />;
}

export default withTranslation()(App);
