import { goToAuth } from '@src/utils/location';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import styles from './Banner.module.scss';

export const Banner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.blueBlurCircle} />
      <div className={styles.backgroundLines} />
      <div className={styles.backgroundWaves}>
        <div className={styles.content}>
          <div className={styles.title}>
            {t('pages.landing.banner.title_1')}
            <br />
            {t('pages.landing.banner.title_2')}
          </div>
          <div className={styles.playWrapper}>
            {/* @TODO: return after demo */}
            {/* <div className={styles.circlePlayBlur} />
                        <PlayIcon className={styles.playIcon} />
                        <div className={styles.play} /> */}
          </div>
          <div className={styles.buttons}>
            <Button
              variant='green'
              className={styles.registrationButton}
              onClick={() => goToAuth('/registration')}
            >
              {t('pages.registration.title')}
            </Button>
            <Button
              variant='link'
              className={styles.loginButton}
              onClick={() => goToAuth('/login')}
            >
              {t('pages.login.title')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
