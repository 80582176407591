import { QueryClientProvider } from '@awl/core/src/components/QueryClientProvider';
import { SnackbarProvider } from '@awl/core/src/components/SnackbarProvider';
import { initI18n } from '@awl/core/src/utils/i18n';
import App from '@src/app';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

initI18n();

const APP_ROOT_ID = 'root';

const container = document.getElementById(APP_ROOT_ID) as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <QueryClientProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </SnackbarProvider>
  </React.StrictMode>
);
