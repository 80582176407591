import { Lang } from '@awl/core/src/types/i18n';
import { ContactData } from '@awl/core/src/types/landing';
import { getFormError } from '@awl/core/src/utils/validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import { ReCaptcha } from '@src/components/ReCaptcha';
import { useReCaptcha } from '@src/components/ReCaptcha/hooks/useReCaptcha';
import { PHONE } from '@src/constants/contacts';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Button } from '../Button';
import styles from './ContactUs.module.scss';
import { useContactUsMutation } from './hooks/useContacUstMutation';
import PatternMobileIcon from './img/pattern-mobile.svg';

interface ContactUsProps {}

export const ContactUs: React.FC<ContactUsProps> = () => {
  const { t, i18n } = useTranslation();
  const contactSubmit = useContactUsMutation();
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup
    .object({
      name: yup.string().required('pages.landing.contact.error.name'),
      email: yup.string().required('pages.landing.contact.error.email'),
      subject: yup.string().required('pages.landing.contact.error.subject'),
      message: yup.string().required('pages.landing.contact.error.message'),
    })
    .required();

  const form = useForm<ContactData>({
    defaultValues: DEFAULT_DATA,
    resolver: yupResolver(schema),
  });

  const reCaptcha = useReCaptcha({
    theme: 'dark',
    namespace: 'contactUs',
    lang: i18n.language as Lang,
    onChange: (val) => form.setValue('reCaptchaResponse', val),
  });

  const onDataSubmit = async (data: ContactData) => {
    if (!data.reCaptchaResponse)
      return enqueueSnackbar(t('forms.auth.errors.no_recaptcha'), {
        variant: 'error',
      });

    await contactSubmit.mutateAsync(data);

    form.reset();
    reCaptcha.reset();

    enqueueSnackbar(t('pages.landing.contact.form.success'), {
      variant: 'success',
    });
  };

  useEffect(reCaptcha.reset, [i18n.language]);

  return (
    <section className={styles.root}>
      <h2
        className={cn(styles.title)}
        dangerouslySetInnerHTML={{
          __html: t('pages.landing.contact.title'),
        }}
      />
      <div className={cn(styles.content)}>
        <div className={cn(styles.pattern)} />
        <div className={styles.contact}>
          <div className={styles.item}>
            <span className={styles.label}>
              {t('pages.landing.contact.form.phone')}
            </span>
            <a
              className={cn(styles.link, styles.value)}
              href={`tel:${PHONE}`}
            >
              {PHONE}
            </a>
          </div>
          <div className={styles.item}>
            <span className={styles.label}>
              {t('pages.landing.contact.located_at')}
            </span>
            <span className={cn(styles.value)}>
              Christoforou Perraivou, 2 KALIA COURT, 2nd floor, Flat/Office 2,
              3025, Limassol, Cyprus
            </span>
          </div>
        </div>
        <form
          className={cn(styles.form)}
          onSubmit={form.handleSubmit(onDataSubmit)}
        >
          <Controller
            control={form.control}
            name='name'
            render={({ field }) => (
              <TextField
                className={cn(styles.muiInput)}
                variant='standard'
                type='text'
                label={t('pages.landing.contact.form.name')}
                inputProps={field}
                helperText={t(getFormError(form.formState, 'name'))}
                error={!!getFormError(form.formState, 'name')}
              />
            )}
          />
          <Controller
            control={form.control}
            name='email'
            render={({ field }) => (
              <TextField
                className={cn(styles.muiInput)}
                variant='standard'
                type='email'
                label={t('pages.landing.contact.form.email')}
                inputProps={field}
                helperText={t(t(getFormError(form.formState, 'email')))}
                error={!!getFormError(form.formState, 'email')}
              />
            )}
          />
          <Controller
            control={form.control}
            name='message'
            render={({ field }) => (
              <TextField
                className={cn(styles.muiInput)}
                variant='standard'
                type='text'
                label={t('pages.landing.contact.form.message')}
                inputProps={field}
                helperText={t(getFormError(form.formState, 'message'))}
                error={!!getFormError(form.formState, 'message')}
              />
            )}
          />
          <div className={styles.action}>
            <div className={styles.recaptcha}>
              {form.formState.isDirty && <ReCaptcha {...reCaptcha} />}
            </div>
            <Button
              type={'submit'}
              variant='green'
              className={styles.btn}
              widthVariant='auto'
              loading={contactSubmit.isLoading}
            >
              {t('pages.landing.contact.form.btn')}
            </Button>
          </div>
        </form>
      </div>
      <PatternMobileIcon className={cn(styles.patternMobile)} />
    </section>
  );
};

const DEFAULT_DATA: ContactData = {
  name: '',
  email: '',
  subject: 'Contact Us',
  message: '',
  reCaptchaResponse: '',
};
