import { useSystemInfo } from '@awl/core/src/hooks/useSystemInfo';
import { useEffect, useRef, useState } from 'react';

export const useTimerLayout = () => {
  const intervalId = useRef<number>();
  const [isDone, setIsDone] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(
    normalizeTimeLeft(LAUNCH_TIME_MS - Date.now())
  );
  const systemInfo = useSystemInfo({ queryOptions: { staleTime: Infinity } });

  // @Note: server unix timestamp in seconds, need multiply by 1000 to get milliseconds
  const timestampMS =
    systemInfo.data?.timestamp && Number(systemInfo.data?.timestamp) * SEC_MS;

  const stopInterval = () => {
    intervalId.current && window.clearInterval(intervalId.current);
  };

  useEffect(() => {
    if (!timestampMS) return;

    setTimeLeft(normalizeTimeLeft(LAUNCH_TIME_MS - timestampMS));

    intervalId.current = window.setInterval(
      () => setTimeLeft((prev) => normalizeTimeLeft(prev - SEC_MS)),
      SEC_MS
    );

    return stopInterval;
  }, [timestampMS]);

  useEffect(() => {
    if (timeLeft >= 0) return;

    stopInterval();
    setIsDone(true);
  }, [timeLeft]);

  return { timeLeft, isDone };
};

// @Note: we need zeros, so let's go negative for 1 second
const normalizeTimeLeft = (time: number) => Math.max(time, -SEC_MS);

const SEC_MS = 1000;
const LAUNCH_DATE = '2022-09-05T10:00:00';
const LAUNCH_TIME_MS = new Date(LAUNCH_DATE).getTime();
