import { useFlagProvider } from '@awl/core/src/providers/useFlagProvider';
import React from 'react';

interface FlagProps {
  children: React.ReactNode;
}

export const Flag: React.FC<FlagProps> = ({ children }) => {
  const { styles: proStyles } = useFlagProvider();

  return <div className={proStyles.root}>{children}</div>;
};
