import { CircularProgress } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import styles from './Button.module.scss';

interface ButtonProps {
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  variant?: 'green' | 'outlined' | 'link';
  type?: 'button' | 'submit' | 'reset';
  icon?: JSX.Element;
  className?: string;
  dataTestId?: string;
  widthVariant?: 'auto' | 'square';
  shape?: 'square';
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const Button: React.FC<ButtonProps> = ({
  variant = '',
  type = 'button',
  disabled,
  loading,
  className = '',
  dataTestId,
  widthVariant = '',
  shape = '',
  icon,
  onClick,
  children,
}) => {
  return (
    <button
      className={cn(
        styles.base,
        styles[variant],
        {
          [styles.disabled]: disabled || loading,
        },
        styles.shape,
        styles[shape],
        styles.width,
        styles[widthVariant],
        className
      )}
      data-testid={dataTestId}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={cn(styles.contentContainer)}>
        {icon && (
          <span className={cn(styles.iconContainer)}>
            {loading ? (
              <CircularProgress
                color='success'
                size={15}
              />
            ) : (
              icon
            )}
          </span>
        )}
        {!icon && loading && (
          <CircularProgress
            color='success'
            size={15}
          />
        )}
        {children}
      </span>
    </button>
  );
};
