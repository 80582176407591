import { LANGS, LANGS_CONFIG } from '@awl/core/src/constants/i18n';
import { useLangSwitchProvider } from '@awl/core/src/providers/useLangSwitchProvider';
import { Lang } from '@awl/core/src/types/i18n';
import cn from 'classnames';
import React from 'react';
import { Chevron } from '../Chevron';
import { Dropdown } from '../Dropdown';
import { Flag } from '../Flag';
import ChevronIcon from './img/chevron.svg';
import EnIcon from './img/en.svg';
import RuIcon from './img/ru.svg';
import UkIcon from './img/uk.svg';
import styles from './LangSwitch.module.scss';

interface LangSwitchProps {
  loading?: boolean;
  isOpenByToggle?: boolean;
  onToggle?: (isOpen?: boolean) => void;
  title: 'icon' | 'code';
  currentClassName?: string;
  dropdownClassName?: string;
  onChange: (lang: Lang) => void;
}

export const LangSwitch: React.FC<LangSwitchProps> = ({
  loading,
  onToggle,
  isOpenByToggle,
  title,
  currentClassName,
  dropdownClassName,
  onChange,
}) => {
  const {
    rootRef,
    toggle,
    language,
    isOpenAll,
    styles: proStyles,
  } = useLangSwitchProvider({
    onToggle,
    isOpenByToggle,
  });

  const CurrentLangIcon = ICONS_MAP[language as Lang];
  return (
    <div
      className={cn(proStyles.root, { [proStyles.loading]: loading })}
      ref={rootRef}
    >
      <div
        className={cn(proStyles.current, styles.current, currentClassName)}
        onClick={toggle}
      >
        {title === 'icon' ? (
          <Flag>
            <CurrentLangIcon />
          </Flag>
        ) : (
          LANGS_CONFIG[language as Lang].key
        )}
        <Chevron isOpen={isOpenAll}>
          <ChevronIcon />
        </Chevron>
      </div>
      <Dropdown
        className={dropdownClassName}
        isOpen={isOpenAll}
        items={Object.values(LANGS_CONFIG).map(({ title, key }) => {
          const Icon = ICONS_MAP[key];
          const isActive = language === key;

          return {
            id: key,
            isActive,
            onClick: () => onChange(key),
            title: (
              <div className={cn(styles.langItem)}>
                {title}
                <Flag>
                  <Icon />
                </Flag>
              </div>
            ),
          };
        })}
      />
    </div>
  );
};

const ICONS_MAP = {
  [LANGS.ru]: RuIcon,
  [LANGS.uk]: UkIcon,
  [LANGS.en]: EnIcon,
};
