import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styles from './Nav.module.scss';

interface NavProps {
  onClick: () => void;
}

export const Nav: React.FC<NavProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <ul className={cn(styles.root)}>
      <li className={cn(styles.item)}>
        <NavLink
          to={'/#about'}
          className={cn(styles.link)}
          onClick={onClick}
        >
          {t('pages.landing.about.navTitle')}
        </NavLink>
      </li>
      <li className={cn(styles.item)}>
        <NavLink
          to={'/#advantages'}
          className={cn(styles.link)}
          onClick={onClick}
        >
          {t('pages.landing.advantages.navTitle')}
        </NavLink>
      </li>
      <li className={cn(styles.item)}>
        <NavLink
          to={'/#contact'}
          className={cn(styles.link)}
          onClick={onClick}
        >
          {t('pages.landing.contact.navTitle')}
        </NavLink>
      </li>
      <li className={cn(styles.item, styles.competitionItem)}>
        <a
          className={styles.link}
          href='https://giveaway.dephouse.club/'
          target='_blank'
          rel='noopener noreferrer'
        >
          {t('layout.header.competition')}
        </a>
      </li>
    </ul>
  );
};
